




















import {Component, Vue} from 'vue-property-decorator';
import { JobColors, parseJobColor } from '@/misc/JobStatusColors';
import { JobStatusType } from '@/misc/JobStatusIdentification';
import { namespace } from 'vuex-class';
import { jobStoreGetter } from '@/stores/job.store';

const JobStore = namespace('job');

@Component({
  methods: {
    parseJobColor,
  },
})
export default class JobCalendarLegendComponent extends Vue {

  @JobStore.Getter(jobStoreGetter.JOB_COLORS)
  public jobColors!: JobColors;

  public isOpen: boolean = false;

  private get jobStatus(): string[] {
    return [JobStatusType.DONE, JobStatusType.IN_PROGRESS, JobStatusType.NOT_TOUCHED, JobStatusType.IN_FUTURE, JobStatusType.NOT_PLANNED];
  }

  public toggleState() {
    this.isOpen = !this.isOpen;
  }

  private formatForLocales(text: string) {
    return text.replace('-', '_').toUpperCase();
  }
}
