import Address from '@/models/Address';
import ContactPerson from '@/models/ContactPerson';
import CleanTime from '@/models/CleanTime';
import Area from '@/models/Area';
import Task from '@/models/Task';
import User from '@/models/User';
import Customer from '@/models/Customer';
import Machine from './Machine';
import {VersionControl} from '@/misc/VersionControl';

export default class Location extends VersionControl {

  public static parseFromObject(object: Partial<Location>): Location {
    const location = new Location();
    Object.assign(location, object);

    // Some objects don't have an address
    if (location.address && location.addressId) {
      location.address = Address.parseFromObject(object.address!);
    }
    if (location.customer && location.customerId) {
      location.customer = Customer.parseFromObject(object.customer!);
    }
    location.contactPersons = ContactPerson.parseFromArray(object.contactPersons!) as ContactPerson[];
    location.cleanTimes = CleanTime.parseFromArray(object.cleanTimes!) as CleanTime[];
    location.managers = User.parseFromArray(object.managers!) as User[];
    location.machines = Machine.parseFromArray(object.machines!) as Machine[];
    return location;
  }

  public id?: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public fax?: string;
  public description?: string;
  public size!: string;
  public radius?: number;
  public customerSignNeeded?: boolean;
  public employeeSignNeeded?: boolean;
  public active!: boolean;

  public floor!: string;
  public freeTextField!: string;
  public machines: Machine[]; // default value, if they don't have machines

  public customer?: Customer;
  public customerId!: string;

  /**
   * Address can only be null, when it should be derived from customer (request data). An Address object from the api
   * will not be null in any cases, because it is at least derived from customer (response data)
   */
  private _address?: Address | null;

  /**
   * contactPerson can only be null, when it should be derived from customer (request data). A contactPerson object from the api
   * will not be null in any cases, because it is at least derived from customer (response data)
   */
  public contactPersons?: ContactPerson[];
  public managers: Partial<User>[] = [];

  /**
   * Address can only be null, when it should be derived from customer
   */
  public addressId!: string | null;

  public createdAt!: Date;
  public updatedAt!: Date;
  public deleted!: boolean;
  public vcOriginId?: string | null;
  public companyId!: string;

  // TODO: Delete these
  public cleanTimes: CleanTime[] = [];

  // TODO: Delete these
  public areas: Area[] = [];
  public areasCount!: number;
  public areasCountActive!: number;
  public tasks: Task[] = [];


  constructor() {
    super();
    this.address = new Address();
    this.contactPersons = [];
    this.machines = [new Machine(), new Machine()];
  }

  /**
   * Return a swallow object copy
   */
  public copy(): Location {
    return Location.parseFromObject(this.parseToObject());
  }

  // RN-388 just a swallow copy ... maybe this is not enough...
  public parseToObject(): Partial<Location> {
    const tmpAddress = this.address ? this.address.parseToObject() : null;
    delete this._address;
    const tmp: {[key in keyof Location]: any} = { ...this };
    tmp.address = tmpAddress;
    tmp.contactPersons = tmp.contactPersons?.map((cp: ContactPerson) => cp.parseToObject()) ?? [];
    tmp.cleanTimes = (tmp.cleanTimes ?? []).slice();
    tmp.areas = tmp.areas?.slice();
    tmp.tasks = tmp.tasks?.slice();
    tmp.managers = tmp.managers?.slice();
    tmp.machines = tmp.machines?.slice();
    return tmp;
  }

  public get address(): Address | null {
    return (this._address ?? this.customer?.address) ?? new Address();
  }

  public set address(address: Address | null) {
    this._address = address;
  }

  public get derivedContactPersons(): ContactPerson[] {
    return (this.contactPersons?.length ? this.contactPersons : this.customer?.contactPersons) ?? [];
  }
}
