



























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import Customer from '@/models/Customer';
import ACTT from '@/models/ACTT';
import WorkSession from '@/models/WorkSession';
import i18n from '@/i18n';
import {namespace} from 'vuex-class';
import {cleanTimeStoreActions} from '@/stores/cleanTime.store';
import ActtComponent from '@/components/actt/Actt.component.vue';
import SignatureComponent from '@/components/shared/Signature.component.vue';
import User from '@/models/User';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {TabItem} from '@/interfaces/TabItem';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import moment from 'moment';
import AreaTabComponent from '@/components/shared/SideCard/AreaTab.component.vue';
import Task from '@/models/Task';
import SidarBarDetailsComponent from '@/components/time-tracking/SidarbarDetails.component.vue';
import TrackingBarDetailsComponent from '@/components/time-tracking/TrackingBarDetails.component.vue';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import Address from '@/models/Address';

const CleanTimeStore = namespace('cleanTime');
const JobStore = namespace('job');
const CustomerStore = namespace('customer');

/**
 * This component represents the content of the side card used to display job details in the jobs overview.
 */
@Component({
  components: {
    TrackingBarDetailsComponent,
    SidarBarDetailsComponent,
    RJTabs,
    ActtComponent,
    SignatureComponent,
    UserInitialsComponent,
    AreaTabComponent,
    WorkSessionManageComponent: () => import(
        '@/components/work-session/WorkSessionManageComponent.vue'),
  },
})
export default class JobDetailSidebarContentComponent extends Vue {

  @CleanTimeStore.Action('loadACTTsAction')
  public loadACTTsAction!: (payload: { cleanTimeId: string, date?: string }) => Promise<ACTT[]>;
  @JobStore.Action('loadWorkSessionAction')
  public loadWorkSessionAction!: (workSessionId: string) => Promise<WorkSession>;
  @CustomerStore.Action('loadCustomerAction')
  public loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Action('loadLocationAction')
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CleanTimeStore.Action(cleanTimeStoreActions.GET_CLEAN_TIME_ACTION)
  public getCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @CustomerStore.Getter('customer')
  public customer!: Customer;

  @Prop({default: true})
  public deletable!: boolean;
  /**
   *  Decides whether or not the object should be editable
   */
  @Prop({default: true})
  public editable!: boolean;

  private get isCleanTimeEditable() {
    return this.editable && !this.job?.cleanTime.isFinished();
  }

  /**
   *  Decides whether or not the object should be copyable
   */
  @Prop({default: true})
  public copyable!: boolean;
  /**
   * The current event to be displayed in the card. Matches the type of selectedEvent in the jobs overview
   */
  @Prop({default: () => null})
  public selectedEvent!: {
    job: Job,
    userData: JobUserDataInterface[],
  } | null;
  public workSessionActts: ACTT[] = [];
  public workSessions: WorkSession[] = [];

  /**
   * The currently selected customer. Null whenever a new customer is being loaded by selectedEvent watcher
   */
  private loadedCustomer: Customer | undefined = new Customer();
  private loadedLocation: Location | undefined = new Location();
  private loadedCleanTime: CleanTime | undefined = new CleanTime();
  // expander
  private tabsModel: number = 0;
  private isOver: boolean = false;
  private showWSManageComponent: boolean = false;
  private showEditIcon: boolean = true;
  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};

  constructor() {
    super();
  }

  get job(): Job | undefined {
    const job = this.selectedEvent ? this.selectedEvent.job : undefined;
    this.showEditIcon = job ? moment().isAfter(moment(job.cleanTimeOccurrence.start)) : false;
    return job;
  }

  get address(): Address {
    return this.loadedLocation?.address ?? this.customer?.address!;
  }

  private get usersWithoutWorkSession(): User[] | undefined {
    return this.job?.cleanTime.plannedUsers.filter((user) =>
        !this.workSessions.some((session) => (session.user as User).id === (user as User).id)) as User[];
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'employees',
        text: this.$t('GENERAL.EMPLOYEES').toString(),
        available: true,
      }, {
        key: 'areas',
        text: this.$t('GENERAL.AREAS').toString(),
        available: true,
      }, {
        key: 'comments',
        text: this.$t('TIMETRACKING.HEADER.COMMENTS').toString(),
        available: this.jobHasComments(),
      }, {
        key: 'signatures',
        text: this.$t('GENERAL.SIGNATURES').toString(),
        available: this.showSignatureTab,
      },
    ];
  }

  /**
   * returns true if any of the worksessions have a comment
   */
  public jobHasComments(): boolean {
    let hasComments = false;
    this.workSessions.forEach((ws) => ws.comment ? hasComments = true : ws.comment);
    return hasComments;
  }

  private onWSManageButtonClick() {
    this.showEditIcon = false;
    this.showWSManageComponent = !this.showWSManageComponent;
  }

  /**
   * returns true if any of the worksessions have a signature
   */
  public jobHasSignatures(): boolean {
    let hasSignatures = false;
    this.workSessions.forEach((ws) => ws.customerSign || ws.employeeSign ? hasSignatures = true : ws);
    return hasSignatures;
  }

  public get showSignatureTab(): boolean {
    return (this.job !== undefined) && (this.job.location.customerSignNeeded || this.job.location.employeeSignNeeded)! &&
        this.workSessions.length > 0 && this.jobHasSignatures();
  }

  get locale(): string {
    return i18n.locale;
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  public findACTTForId(id: string): ACTT | null {
    const actt = this.workSessionActts.find((a) => a.id === id || a.origin === id);
    return actt ? actt : null;
  }

  public findWorkSessionForId(actt: ACTT): any {
    if (!actt || !actt.sessionACTT) {
      return null;
    } else {
      return this.workSessions.find((ws) => ws.id === actt.sessionACTT!.workSession)!;
    }
  }

  public getCheckedTasks(): Task[] {
    const tasks: Task[] = [];
    this.selectedEvent?.job.workSessions.forEach((session) => session.checkedTasks?.forEach((task) => tasks.push(task)));
    return tasks;
  }

  /**
   * When the selected event changes, update all information not immediately contained in the event.
   * @private
   */
  @Watch('selectedEvent')
  private onEventChanged() {
    console.log(this.selectedEvent);
    this.showWSManageComponent = false;
    if (this.job && this.job.customer) {
      this.tabsModel = 0;
      // Prevent behaviour where irrelevant customer information would still be displayed while loading the new
      // information
      this.loadedCustomer = undefined;
      // Load new customer info from store
      this.loadCustomerAction(this.job.customer.id as string).then((value) => {
        this.loadedCustomer = value;
      });

      this.loadedLocation = undefined;
      this.loadLocationAction({locationId: this.job.location.id as string, shouldBeStored: true}).then((value) => {
        this.loadedLocation = value;
      });

      this.getCleanTimeAction(this.selectedEvent?.job.cleanTime.id!).then((value) => {
        this.loadedCleanTime = value;
      });
      this.isOver = moment().isAfter(moment(this.selectedEvent!.job.cleanTimeOccurrence.end));

      // Gather all Worksession promises through our job
      const worksessionPromises: Array<Promise<WorkSession>> =
          this.job.workSessions.map((entry) => this.loadWorkSessionAction(entry.id));

      // Try to resolve all promises, fill our worksession associated arrays
      Promise.all(worksessionPromises).then((value) => {
        if (this.job) {
          this.job.workSessions = value;
          // add all worksession actts to our worksession actt array
          let workSessions: WorkSession[] = [];
          for (const worksession of this.job.workSessions) {
            workSessions = workSessions.concat(worksession);
          }
          this.workSessions = workSessions;
        }
      }).catch(() => {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      });
    }
  }

  // TODO: Rename 'Dashboard' to 'Details'. Make constants for view names. Make one Method for routing here
  public onUserClick(user: User) {
    this.$router.push({
      name: 'userDetails', params: {
        companyId: this.$route.params.companyId,
        userId: user.id!,
      },
    });
  }
}
