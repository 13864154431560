import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import Area from '@/models/Area';
import { CancelToken, CancelTokenSource } from 'axios';
import {FindAllResponse} from '@/interfaces/Responses';

export default class CustomerRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public loadCustomers(companyId: string): Promise<FindAllResponse<Customer>> {
        return this.axiosClient.get(`customer?companyId=${companyId}`, this.cancelTokenSourceOption);
    }

    // TODO add skip and limit
    public loadCustomerVc(id: string, skip?: number, limit?: number): Promise<FindAllResponse<Customer>> {
        return this.axiosClient.get(`customer?vcOriginId=${id}&vcIsLogEntity=true&populate=vcAuthor,invoiceAddress`);
    }


    public loadColor(cleanTimeId: string): Promise<any> {
        return this.axiosClient.get(`colorMapper?source=${cleanTimeId}`, this.cancelTokenSourceOption);
    }

    public loadSingleCustomer(customerId: string): Promise<any> {
        return this.axiosClient.get(`customer/${customerId}`, this.cancelTokenSourceOption);
    }

    public create(customer: Customer): Promise<any> {
        return this.axiosClient.post(`customer`, customer, this.cancelTokenSourceOption);
    }

    public edit(customer: Partial<Customer>): Promise<any> {
        return this.axiosClient.patch(`customer/${customer.id}`, customer, this.cancelTokenSourceOption);
    }

    public delete(customer: Partial<Customer>): Promise<any> {
        return this.axiosClient.delete(`customer/${customer.id}`, this.cancelTokenSourceOption);
    }

    public createLocation(location: Record<string, any>): Promise<any> {
        return this.axiosClient.post(`location`, location, this.cancelTokenSourceOption);
    }

    public editLocation(location: Partial<Location>): Promise<any> {
        return this.axiosClient.patch(`location/${location.id}`, location, this.cancelTokenSourceOption);
    }

    public deleteLocation(location: Partial<Location>): Promise<any> {
        return this.axiosClient.delete(`location/${location.id}?populate=customer`, this.cancelTokenSourceOption);
    }

    public loadLocation(locationId: string): Promise<any> {
        return this.axiosClient.get(`location/${locationId}`, this.cancelTokenSourceOption);
    }
    // TODO add skip and limit
    public loadLocationVc(id: string, skip?: number, limit?: number): Promise<FindAllResponse<Location>> {
        return this.axiosClient.get(`location?vcOriginId=${id}&vcIsLogEntity=true&populate=vcAuthor`);
    }

    public loadCustomersLocations(query: string): Promise<FindAllResponse<Location>> {
        return this.axiosClient.get(`location?${query}`, this.cancelTokenSourceOption);
    }

    public updateCleanTime(cleanTime: CleanTime): Promise<any> {
        return this.axiosClient.patch(`cleanTime/${cleanTime.id}`, cleanTime, this.cancelTokenSourceOption);
    }

    public createCleanTime(cleanTime: CleanTime): Promise<any> {
        return this.axiosClient.post(`/cleanTime`, cleanTime, this.cancelTokenSourceOption);
    }

    public createArea(area: Area): Promise<any> {
        return this.axiosClient.post(`/area`, area, this.cancelTokenSourceOption);
    }

    public deleteArea(areaId: string): Promise<any> {
        return this.axiosClient.delete(`/area/${areaId}`, this.cancelTokenSourceOption);
    }

    /**
     * Cancels all request of this repository and applies a new cancel token
     */
    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }
}
