





























































































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {fileStoreActions, fileStoreGetter} from '@/stores/file.store';
import DbFile, {FileEntities, Label} from '@/models/DbFile';
import {Permission} from '@/misc/enums/permission.enum';
import {saveAs} from 'file-saver';

const FileStore = namespace('file');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    TableComponent: () => import('@/components/shared/table/Table.component.vue'),
    RJSelect: () => import('@/components/shared/custom-vuetify/RJSelect.vue'),
    SearchBarComponent: () => import('@/components/shared/SearchBar.component.vue'),
    LabelComponent: () => import('@/components/shared/Label.component.vue'),
  },
})

export default class UploadFileComponent extends Vue {

  @FileStore.Action(fileStoreActions.LOAD_FILES_ACTION)
  private loadFilesAction!: (payload: { entity: FileEntities, value: string }) => Promise<DbFile[]>;

  @FileStore.Action(fileStoreActions.DOWNLOAD_FILES_ACTION)
  private downloadFileAction!: (fileId: string) => Promise<Blob>;

  @FileStore.Action(fileStoreActions.DELETE_FILE_ACTION)
  private deleteFileAction!: (id: string) => Promise<void>;

  @FileStore.Action(fileStoreActions.CREATE_FILE_ACTION)
  private createFileAction!: (file: FormData) => Promise<DbFile>;

  @FileStore.Action(fileStoreActions.UPDATE_FILE_ACTION)
  private updateFileAction!: (body: {id: string, isInAppVisible: boolean}) => Promise<DbFile>;

  @FileStore.Getter(fileStoreGetter.FILES)
  private _files!: DbFile[];

  private get files() {
    return this._files;
  }

  @Prop({required: true})
  public entityId!: string;

  @Prop({required: true})
  public entity!: FileEntities;

  @Prop({default: ''})
  public height!: string;

  private isInAppVisible: boolean = false;

  private showDeleteDialog: boolean = false;
  private showCreateDialog: boolean = false;
  private selectedFile: Partial<DbFile> | null = null;
  private fileLabel: string | Label = '';
  private loading: boolean = false;
  private searchString: string = '';

  private get importantKeys() {
    return {
      name: String,
      label: String,
    };
  }

  private closeDeleteDialog() {
    this.selectedFile = null;
    this.showDeleteDialog = false;
    this.isInAppVisible = false;
  }

  get tableHeaders(): any[] {
    return [
      // the names are not displayed
      {text: this.$t('GENERAL.NAME').toString(), value: 'name'},
      {text: '', value: 'actions', class: 'ml-auto'},
    ];
  }

  public async mounted() {
    this.loading = true;
    await this.loadFilesAction({entity: this.entity, value: this.entityId});
    this.loading = false;
  }

  private async download(file: DbFile) {
    saveAs(await this.downloadFileAction(file.id), file.name);
  }

  private async openInBrowser(file: DbFile) {
    const blob = await this.downloadFileAction(file.id);

    // Ensure the MIME type is properly specifie

    // Create a URL and open it in a new tab
    const url = window.URL.createObjectURL(blob);
    const newTab = window.open(url, '_blank');

    if (!newTab) {
      console.error('Unable to open a new tab. Please allow pop-ups.');
    }

    // Revoke the object URL after a delay to ensure the file is fully loaded in the new tab
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }



  private onUploadFileIntent() {
    this.showCreateDialog = true;
  }

  private get fileName() {
    return this.selectedFile?.name ?? '';
  }

  private createOnNextTick(file: File) {
    this.$nextTick(() => this.createAndUploadFile(file));
  }

  private async createAndUploadFile(file: File) {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('name', file.name);
    fileData.append(this.entity, this.entityId);
    fileData.append(`companyId`, this.$route.params.companyId);
    fileData.append(`isInAppVisible`, this.isInAppVisible + '');
    if (this.fileLabel) {
      const label = typeof this.fileLabel === 'string' ?
        this.fileLabel :
        this.fileLabel.name;
      if (label?.trim()) {
        fileData.append('label', label);
      }
    }
    this.showCreateDialog = false;
    this.isInAppVisible = false;
    this.selectedFile = null;
    this.fileLabel = '';
    this.loading = true;
    try {
      await this.createFileAction(fileData);
      this.$notifySuccessSimplified('MANAGE.FILE_CREATE_MESSAGE.SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('MANAGE.FILE_CREATE_MESSAGE.ERROR');
    } finally {
      this.loading = false;
    }
  }

  private updateLabel(label: string) {
    this.fileLabel = label;
  }

  public onRemoveClick(file: DbFile) {
    this.selectedFile = file;
    this.showDeleteDialog = true;
  }

  private async deleteFile() {
    try {
      await this.deleteFileAction(this.selectedFile!.id!);
      this.$notifySuccessSimplified('MANAGE.FILE_DELETE_MESSAGE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('MANAGE.FILE_DELETE_MESSAGE.ERROR');
    } finally {
      this.closeDeleteDialog();
    }
  }


  private async updateAppVisibility(id: string, value: boolean) {
    await this.updateFileAction({id, isInAppVisible: value});
  }

  @Watch('showCreateDialog')
  private watchShowCreateDialog() {
    if (!this.showCreateDialog) {
      this.isInAppVisible = false;
    }
  }
}
