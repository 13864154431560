

















































































import {Component, Vue} from 'vue-property-decorator';
import {TabItem} from '@/interfaces/TabItem';
import {namespace} from 'vuex-class';
import Company from '@/models/Company';
import User from '@/models/User';
import CompanyMasterDataComponent from '@/components/company/CompanyMasterData.component.vue';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import CompanyArchiveComponent from '@/components/company/CompanyArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {Permission} from '@/misc/enums/permission.enum';
import {fileStoreActions, fileStoreGetter} from '@/stores/file.store';
import {Label} from '@/models/DbFile';
import UploadFile from '@/components/shared/UploadFile.component.vue';
import ColorCodeManage from '@/components/shared/ColorCodeManage.component.vue';

const CompanyStore = namespace('company');
const AuthStore = namespace('auth');
const FileStore = namespace('file');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    ColorCodeManage,
    UploadFile,
    RJTabs,
    CompanyArchiveComponent,
    CompanyMasterDataComponent,
    SideCardComponent,
    CompanyManageComponent: () => import(
      /* webpackChunkName: "CompanyManageComponent" */
      '@/components/company/CompanyManage.component.vue'),
    UserPasswordComponent: () => import(
      /* webpackChunkName: "UserPasswordComponent" */
      '@/components/user/UserPassword.component.vue'),
    UserProfileComponent: () => import(
      /* webPackChunkName: "UserProfileComponent */
      '@/components/user/UserProfile.component.vue'),
    LabelTableComponent: () => import(
      /* webPackChunkName: "LabelTableComponent */
      '@/components/shared/LabelTable.component.vue'),
    RoleDashboardComponent: () => import(
      /* webpackChunkName: "RoleDashboardComponent" */
      '@/components/role/RoleDashboard.component.vue'),
  },
})
export default class SettingsView extends Vue {

  private selectedTab: number = 0;
  public showCompanyEditSideCard: boolean = false;


  @CompanyStore.Getter('activeCompany')
  private _company?: Company;

  @CompanyStore.Action('loadActiveCompanyAction')
  private findCompany!: (id: string) => Promise<Company>;

  @AuthStore.Getter('user')
  private _user!: User;

  @FileStore.Action(fileStoreActions.CREATE_LABEL_ACTION)
  private createLabelsAction!: (label: Label) => Promise<Label>;

  @FileStore.Action(fileStoreActions.LOAD_LABELS_ACTION)
  private loadLabelsAction!: (companyId: string) => Promise<Label[]>;

  @FileStore.Action(fileStoreActions.DELETE_LABEL_ACTION)
  private deleteLabelsAction!: (labelId: string) => Promise<void>;

  @FileStore.Getter(fileStoreGetter.LABELS)
  private _labels!: Label[];

  get labels() {
    return this._labels;
  }

  get company(): Company {
    return this._company ? this._company : new Company();
  }

  get companyId() {
    return this.$route.params.companyId;
  }

  get user(): User {
    return this._user ? this._user : new User();
  }

  private get tabItems(): TabItem[] {
    return [
      {
        key: 'company',
        text: this.$t('SETTINGS.COMPANY_PROFILE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.COMPANY_READ_OWN) && !!this.company.id,
      },
      {
        key: 'role',
        text: this.$t('GENERAL.ROLES').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.ROLE_READ) && !!this.company.id,
      },
      {
        key: 'password',
        text: this.$t('GENERAL.PASSWORD').toString(),
        available: true,
      },
      {
        key: 'labels',
        text: this.$t('GENERAL.LABEL').toString(),
        available: true,
      },
      {
        key: 'colorCodes',
        text: this.$t('GENERAL.COLOR_CODES').toString(),
        available: true,
      },
      {
        key: 'files',
        text: this.$t('CUSTOMER_DASHBOARD.DOCUMENTS').toString(),
        available: true,
      },
      {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.COMPANY_UPDATE_OWN) && !!this.company.id,
      },
    ];
  }

  public async mounted() {
    await this.findCompany(this.$route.params.companyId);

    await this.loadLabelsAction(this.$route.params.companyId);
  }

  public onEditCompany() {
    this.showCompanyEditSideCard = true;
  }

  public onExitModal() {
    this.showCompanyEditSideCard = false;
  }
}
