

































































import {Component, VModel, Vue} from 'vue-property-decorator';
import {Permission, PermissionLevels, PermissionObjects} from '@/misc/enums/permission.enum';
import UserRole from '@/models/user-attributes/UserRole';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    RJSelect,
  },
})
export default class RoleManage extends Vue {
  @VModel()
  private selectedRole!: UserRole;

  private accessLevels = PermissionLevels;
  private permissionTypes = PermissionObjects;
  private selectValues(name: string, level: string): Array<{ text: string, value: string | undefined }> {
    // create is special, there is no OWN appendix
    if (level === 'CREATE' || (name === 'ROLE' && level !== 'READ')) {
      return [
        {
          text:  '-',
          value: undefined,
        }, {
          text: this.$t('ROLE_MANAGE.ALL').toString(),
          value: `${name}_${level}`,
        },
      ];
    } else {
      return [
        {
          text: '-',
          value: undefined,
        },
        {
          text: this.$t('ROLE_MANAGE.OWN').toString(),
          value: `${name}_${level}_OWN`,
        },
        {
          text: this.$t('ROLE_MANAGE.ALL').toString(),
          value: `${name}_${level}`,
        },
      ];
    }
  }

  private disabledCondition(permission: string, level: string) {
    return ['WORKSESSION_DELETE', Permission.COMPANY_DELETE].includes(`${permission}_${level}`);
  }

  private arrayCondition(value: string, valueToFind: { text: string, value: string | null } | string) {
    return valueToFind && value.includes(typeof valueToFind === 'string' ? valueToFind : valueToFind.value!);
  }
}
