var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-scroll d-flex flex-column",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.colorArray),function(jobColor){return _c('v-menu',{key:jobColor.jobStatus,attrs:{"close-on-content-click":false,"offset-y":"","max-width":"320","nudge-right":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticStyle:{"justify-items":"center"}},'v-col',attrs,false),on),[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t(("JOB_OVERVIEW.LEGEND.CALENDAR_EVENT." + (jobColor.jobStatus))))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('CalendarEventTile',{attrs:{"event-color":jobColor,"event":_vm.exampleEvent}})],1)],1)],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-color-picker',{model:{value:(jobColor.colorCode),callback:function ($$v) {_vm.$set(jobColor, "colorCode", $$v)},expression:"jobColor.colorCode"}}),_c('v-list',[_c('v-list-item',[_c('v-checkbox',{attrs:{"label":_vm.$t('GENERAL.HATCHED')},model:{value:(jobColor.hatched),callback:function ($$v) {_vm.$set(jobColor, "hatched", $$v)},expression:"jobColor.hatched"}})],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"small-grow",attrs:{"color":"secondary","outlined":"","icon":""},on:{"click":function($event){return _vm.resetToDefault(jobColor.jobStatus)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-backup-restore ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.RESET_TO_STANDARD'))+" ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","outlined":"","icon":""},on:{"click":function($event){return _vm.discard(jobColor.jobStatus)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-undo ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('USER.CLOSE_MANAGE_DIALOG.DISCARD'))+" ")])])],1)],1)],1)],1)],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"small-grow",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.resetToDefault()}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.RESET_TO_STANDARD'))+" ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.discard()}}},[_vm._v(" "+_vm._s(_vm.$t('USER.CLOSE_MANAGE_DIALOG.DISCARD'))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.SAVE'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }