









































































import {Component, Prop, Vue} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import Job from '@/models/Job';
import moment from 'moment';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import WorkSessionManageComponent from '@/components/work-session/WorkSessionManageComponent.vue';
import User from '@/models/User';
import {convertMs} from '@/helper/JobTimeHelper';

@Component({
  components: {
    UserInitialsComponent,
    WorkSessionManageComponent,
  },
})
export default class TrackingBarDetailsComponent extends Vue {

  @Prop({default: () => new Job()})
  public session?: Job;
  @Prop({default: () => new WorkSession()})
  public selectedWorksession?: WorkSession;
  @Prop({default: () => new User()})
  public workSessionAuthor?: User;
  @Prop({default: false})
  public isJob!: boolean;
  public showWSManageComponent: boolean = false;
  private showWorkSession: boolean = false;


  public onWSManageButtonClick() {
    this.showWSManageComponent = !this.showWSManageComponent;
    this.$emit('worksession-update');
  }

  public getBetweenDay(startTime: string, endTime: string, startTimeOnly: boolean = false) {
    // Get Moment Object from start and end
    const start = moment(startTime);
    const end = moment(endTime);

    // Date is not valid, the job wasnt worked on
    // Return Time representation
    return startTimeOnly ? `${start.format('DD.MM.yy')}` : `${start.format('DD.MM.yy')} - ${end.format('DD.MM.yy')}`;
  }

  public getFormattedTime(startTime: string, duration: number, startTimeOnly: boolean = false) {
    // Get Moment Object from start and end
    const start = moment(startTime);
    const end = moment(startTime).add(duration);

    // Date is not valid, the job wasnt worked on
    if (!start.isValid() || !end.isValid()) {
      return this.$t('TIMETRACKING.MESSAGES.NOT_DETERMINED').toString();
    }
    // Return Time representation
    return startTimeOnly ? `${start.format('HH:mm')} ${this.$t('GENERAL.CLOCK').toString()}` : `${start.format('HH:mm')} ${this.$t('GENERAL.CLOCK').toString()}` +
        ` - ` +
        `${end.format('HH:mm')} ${this.$t('GENERAL.CLOCK').toString()}`;
  }

  public showWorksession() {
    this.showWorkSession = !this.showWorkSession;
  }

  public get locale(): string {
    return this.$i18n.locale;
  }

  public hasDurationTracked(item: WorkSession): boolean {
    return !(!item.durationTracked || item.canceledByReason);
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()} ${this.$t('GENERAL.UNITS_SHORT.HOUR')}  ${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS_SHORT.MINUTES')}.`;
  }

  public getWorkSessionRealTimeStatus(item: WorkSession): string {
    if (this.isWorkSessionAborted(item)) {
      return this.$t('JOB_OVERVIEW.JOB_CANCELLED')?.toString() || '';
    }
    return '';
  }

  public isWorkSessionAborted(item: WorkSession) {
    return !!item.canceledByReason;
  }

  public getDurationDeltaString(duration: number, durationTracked: number) {
    // Calculate duration delta
    const delta = Math.abs(duration - durationTracked);

    // convert delta to a neat time object
    const time = convertMs(delta);

    // pad absolute hours and minutes at start to have two numbers at all times
    const hours = (time.hour + (time.day * 24)).toString();
    const minutes = time.minute.toString().padStart(2, '0');

    // check if its negative or positive.
    let prefixString = '';
    if (durationTracked < duration) {
      prefixString = '-';
    } else if (durationTracked > duration) {
      prefixString = '+';
    }

    // return formatted string
    const abbr = this.$t('GENERAL.UNITS.ABBRV.HOUR').toString();
    return `${prefixString}${hours}:${minutes} ${abbr}`;
  }


  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};

}

