








































import {Component, Prop, Vue} from 'vue-property-decorator';
import Job, {JobColor} from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import {namespace} from 'vuex-class';
import {jobStoreGetter} from '@/stores/job.store';
import {JobStatusType} from '@/misc/JobStatusIdentification';
import {JobColors, parseJobColor} from '@/misc/JobStatusColors';

const JobStore = namespace('job');

@Component({
  components: {
    UserInitialsComponent: () => import(
        '@/components/user/UserInitials.component.vue'),
  },
})
export default class CalendarEventTile extends Vue {

  @JobStore.Getter(jobStoreGetter.JOB_COLORS)
  public jobColors!: JobColors;

  private USERS_TO_SHOW = 3;

  @Prop({default: undefined})
  public event: { job: Job, userData: JobUserDataInterface[] } | undefined;

  @Prop()
  public eventColor: JobColor | undefined;

  public isHatched(): boolean {

    const jobStatus = this.event?.job?.status ?? JobStatusType.IN_FUTURE;

    return (this.eventColor ?? this.jobColors[jobStatus]).hatched;
  }

  public getIconColor() {
    if (this.isHatched()) {
      return `color: black;`;
    }
    const eventCss = this.getEventColor();
    const match = eventCss.match(/color:\s*([^;]+);/);
    const iconColor = match ? match[1].trim() : 'black';

    return `color: ${iconColor};`;
  }

  /**
   *  Determines the color for the given event. The determined color is associated with an event status.
   */
  public getEventColor(): string {
    // If no Job was defined, show empty space
    if (!this.event || typeof this.event.job !== 'object') {
      return 'empty-day-border';
    }

    const jobStatus = this.event.job?.status ?? JobStatusType.IN_FUTURE;

    const color = this.jobColors[jobStatus];

    return parseJobColor(this.eventColor ?? color);
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  private showJobDetails() {
    if (this.event) {
      this.$emit('showDetails', this.event);
    }
  }
}
