



























import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import {TabItem} from '@/interfaces/TabItem';

@Component
export default class RJTabs extends Vue {

  @VModel({default: 0})
  private tabsModel!: number;

  @Prop({default: () => []})
  private tabItems!: TabItem[];

  private get getAvailableTabs(): TabItem[] {
    return this.tabItems.filter((item) => item.available !== false);
  }

  @Prop({default: ''})
  private height!: string;


  private getStyle(index: number) {
    const hideScrollbar = this.getAvailableTabs[index].hideScrollbar;
    return `max-height: ${this.height}; overflow-y: ${hideScrollbar ? 'hidden!important' : ''};`;
  }
}
