














































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import CalendarEventTile from '@/components/shared/CalendarEventTile.component.vue';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import Job, { JobColor } from '@/models/Job';
import { jobStoreActions, jobStoreGetter, jobStoreMutations } from '@/stores/job.store';
import { DEFAULT_JOB_COLORS, JobColors, parseJobColor } from '@/misc/JobStatusColors';
import { JobStatusType } from '@/misc/JobStatusIdentification';

const JobStore = namespace('job');

@Component({
  methods: { parseJobColor },
  components: {
    CalendarEventTile,
  },
})

export default class ColorCodeManageComponent extends mixins(ErrorMessageHandlerMixin) {

  @JobStore.Action(jobStoreActions.GET_JOB_COLOR_CODES)
  private loadJobColorsAction!: (companyId: string) => Promise<JobColor[]>;
  @JobStore.Action(jobStoreActions.SAVE_JOB_COLOR_CODES)
  private saveJobColorsAction!: (payload: {
    companyId: string,
    payload: JobColor[],
  }) => Promise<void>;
  @JobStore.Mutation(jobStoreMutations.STORE_JOB_COLORS)
  private storeJobColorsMutation!: (colors: JobColors) => void;
  @JobStore.Getter(jobStoreGetter.JOB_COLORS)
  private _jobColors!: JobColors;

  get jobColors() {
    return this._jobColors;
  }

  public colorArray: JobColor[] = [];

  public exampleEvent: {
    job: Job,
    userData: JobUserDataInterface[],
  } = {
    job: Job.parseFromObject({
      customer: {
        name: 'Ihr Kunde',
      } as any,
      location: {
        name: 'Das zu reinigende Objekt',
      } as any,
      cleanTime: {
        times: [{
          hour: 9,
          minute: 0,
          duration: 7200000,
        }],
      } as any,
      cleanTimeOccurrence: {
        start: '2025-01-07T12:34:04.813Z',
        end: '2025-01-07T12:34:04.813Z',
        duration: 0,
      } as any,
    }),
    userData: [],
  };

  public async mounted() {
    await this.loadJobColorsAction(this.$route.params.companyId);
    this.colorArray = this.parseColorObjectToArray(this.jobColors);
  }

  private parseColorObjectToArray(colorObject: JobColors) {
    return Object.keys(colorObject).map((jobStatus) => ({
      jobStatus: jobStatus as JobStatusType,
      ...colorObject[jobStatus],
    }));
  }

  public async save() {
    try {
      await this.saveJobColorsAction({
        payload: this.colorArray,
        companyId: this.$route.params.companyId,
      });
      this.storeJobColorsMutation(this.colorArray.reduce((result, currentColor) => {
        result[currentColor.jobStatus] = {
          ...currentColor,
        };
        return result;
      }, {}));
      this.$notifySuccessSimplified('COLOR_CODE.SUCCESS');
    } catch (e) {
     this.$notifyErrorSimplified('COLOR_CODE.ERROR');
    }
  }

  public discard(status?: JobStatusType): void {
    this.setColorArrayFromColorObject(this.jobColors, status);
  }

  private setColorArrayFromColorObject(colorObject: JobColors, jobStatus?: JobStatusType) {
    if (jobStatus) {
      const index = this.colorArray.findIndex((color) => color.jobStatus === jobStatus);
      if (index > -1) {
        const tmpArr = this.colorArray.slice();
        tmpArr[index] = {
          jobStatus,
          ...colorObject[jobStatus],
        };
        this.colorArray = tmpArr;
      }
    } else {
      this.colorArray = this.parseColorObjectToArray(colorObject);
    }
  }

  public resetToDefault(status?: JobStatusType): void {
    this.setColorArrayFromColorObject(DEFAULT_JOB_COLORS, status);
  }
}
