













































































import {Component, Vue} from 'vue-property-decorator';
import JobsFilterData from '@/misc/JobsFilterData';
import {namespace} from 'vuex-class';
import Throttle from '@/helper/Throttle';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import JobDetailSidebarContentComponent from '@/components/job/JobDetailSidebarContent.component.vue';
import CleanTime from '@/models/CleanTime';
import Customer from '@/models/Customer';
import {WEEKDAYS} from '@/Constants';
import JobManageComponent from '@/components/job/JobManage.component.vue';
import Location from '@/models/Location';
import moment, {Moment} from 'moment';
import { jobStoreActions } from '@/stores/job.store';


const JobStore = namespace('job');
const CustomerStore = namespace('customer');
const CleanTimeStore = namespace('cleanTime');

@Component({
  components: {
    JobDetailSidebarContentComponent,
    SideCardComponent,
    JobFilterComponent: () => import(
      /* webpackChunkName: "JobFilterComponent" */
      '@/components/job/JobFilter.component.vue'),
    JobCalendarComponent: () => import(
      /* webpackChunkName: "JobCalendarComponent" */
      '@/components/job/JobCalendar.component.vue'),
    JobManageComponent: () => import(
      /* webpackChunkName: "JobManage" */
      '@/components/job/JobManage.component.vue'),

  },
})
export default class JobsOverviewView extends Vue {

  @CustomerStore.Getter('location')
  public _location!: Location;
  @CustomerStore.Getter('customer')
  public _customer!: Customer;
  @CleanTimeStore.Action('getCleanTimeAction')
  public getCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @CleanTimeStore.Action('deleteCleanTimeAction')
  public deleteCleanTimeAction!: (cleanTimeId: string) => Promise<void>;
  @CustomerStore.Mutation('storeLocation')
  public storeLocationMutation!: (location: Location | undefined) => void;
  @CustomerStore.Action('loadLocationAction')
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  /**
   * Defines which date the calendar should focus
   */
  public focus: string | null = null;
  /**
   *  Defines the initial type of the calendar
   */
  public calendarType: 'week' | 'day' | null = null;
  /**
   *
   */
  public autoInsert: boolean = false;
  /**
   * boolean to reset the state of the job creation dialog
   */
  public resetJobDialog: boolean = false;
  public addJobDialog: boolean = false;
  public location: Location = new Location();
  private groupBy: 'none' | 'customer' | 'employee' = 'none';
  @JobStore.Getter('jobs')
  private jobs!: Job[];
  @JobStore.Action('loadJobsAction')
  private loadJobsAction!: (payload: { filterData: JobsFilterData, discardCache?: boolean }) => Promise<Job[]>;
  @JobStore.Action(jobStoreActions.GET_JOB_COLOR_CODES)
  private loadJobColorsAction!: (companyId: string) => Promise<void>;
  @JobStore.Mutation('clearJobs')
  private clearJobs!: () => void;
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;
  /**
   * Filter data for work sessions
   */
  private filterData: JobsFilterData = new JobsFilterData();
  private throttle: Throttle;
  private showSideCard: boolean = false;
  private openDeleteDialog = false;

  /**
   * if true the job is not editable or deletable
   */
  private currentJobIsOver = true;

  private currentJobInfo: {
    job: Job,
    userData: JobUserDataInterface[],
  } | null = null;


  constructor() {
    super();
    this.throttle = new Throttle();
  }

  public created() {
    // reset all jobs in cache
    this.clearCachedJobs();
    // retrieves the filter data from url
    this.readUrl();
    // loads initial the data a considers filter values
    this.loadJobColorsAction(this.$route.params.companyId);
  }

  public onJobInfoChange(value: {
    job: Job,
    userData: JobUserDataInterface[],
  }) {
    this.currentJobInfo = value;
    this.currentJobIsOver = moment().isAfter(moment(value.job.cleanTimeOccurrence.end));
    this.showSideCard = true;
    this.hideJobDialog();
  }

  /**
   * Is called if main filter data changes. Loads new data and discards job cache to get job data that
   * respects filter data
   */
  public loadJobs(data: JobsFilterData) {
    // merge filter data
    Object.assign(this.filterData, data);
    this.filterData.includeSubstitutes = true;
    this.throttle.throttle(async () => {
      try {
        this.updateUrl();
        await this.loadJobsAction({filterData: this.filterData, discardCache: true});
      } catch (e: any) {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    });
  }

  /**
   * Event handler for calendar focus change
   */
  public onCalendarFocusChange(value: string) {
    this.focus = value;
    this.updateUrl();
  }

  /**
   * Event handler for calendar type change
   */
  public onCalendarTypeChange(value: 'week' | 'day') {
    this.calendarType = value;
    this.updateUrl();
  }

  public onWorkSessionUpdate() {
    this.reloadCalender();
    this.showSideCard = false;
  }

  /**
   *  Reads the url filter values and stores them in filterData
   */
  public readUrl() {
    // get main filter values
    this.filterData.company = this.$route.params.companyId as string;
    this.filterData.customer = this.$route.query.customer as string | undefined;
    if (this.$route.query.users) {
      this.filterData.users = (this.$route.query.users as string).split(',');
    }
    if (this.$route.query.locations) {
      this.filterData.locations = (this.$route.query.locations as string).split(',');
    }
    if (this.$route.query.manager) {
      this.filterData.managers = (this.$route.query.manager as string).split(',');
    }
    // get calendar values
    this.focus = this.$route.query.focus as string || null;
    this.calendarType = this.$route.query.calendarType as 'week' | 'day' || null;
  }

  /**
   * Hides the Job Dialog, if abort was pressed
   */
  public hideJobDialog(): void {
    this.addJobDialog = false;
  }

  /**
   * Updates the url values according to the calendar and filter values
   */
  public updateUrl() {
    const urlParams = new URLSearchParams();

    // defines values which should be considered in url
    const urlData: any = {
      customer: this.filterData.customer,
      users: this.filterData.users,
      manager: this.filterData.managers,
      locations: this.filterData.locations,
      focus: this.focus,
      calendarType: this.calendarType,
    };

    Object.keys(urlData)
      .filter((key) => urlData[key]) // no undefined values
      .filter((key) => !(Array.isArray(urlData[key]) && urlData[key].length === 0)) // no empty arrays
      .forEach((key) => urlParams.append(key, urlData[key]));

    // update url
    history.replaceState({}, '', this.$route.path + '?' + urlParams.toString());
  }

  public async onDeleteCleanTime(cleanTimeId: string): Promise<void> {
    try {
      // Send API Request to delete the cleantime with the given id
      await this.deleteCleanTimeAction(cleanTimeId);

      // If API send back a cleanTime Document is probably deleted
      // try to find the given cleanTime in our Location cleantime array
      const indexToDelete = this.location.cleanTimes.findIndex((cleanTime) => cleanTime.id === cleanTimeId);

      // If found delete from array
      if (indexToDelete > -1) {
        this.location.cleanTimes.splice(indexToDelete, 1);
      }

      // Show Success message
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.SUCCESS');

      // Clear Calendar Cache on Remove
      this.clearCachedJobs();

      // Close Delete Dialog
      this.openDeleteDialog = false;
      this.addJobDialog = false;
      await this.reloadCalender();
      // catch all errors
    } catch (e: any) {
      // job was already deleted (by another user)
      if (e.status === 409) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.ALREADY_DELETED');
      } else {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.GENERAL');
      }
    }
  }

  public async addCreatedCleanTime(cleanTime: CleanTime): Promise<void> {
    // add cleanTime to location
    this.location.cleanTimes.push(cleanTime);

    // update location in state
    this.storeLocationMutation(this.location);

    // Clear Jobs, so that the Calendar will reload its data
    await this.reloadCalender();
  }

  public getRecurrenceFromByWeek(cleanTime: CleanTime): string {
    let recurrence = '1';

    // Check if the frequency is not daily
    // and if the byWeek Array has a length greater then zero
    if (cleanTime!.freq! !== 'daily' && cleanTime!.byWeekDay!.length > 0) {

      // Get first byWeekDay entry
      const day = cleanTime.byWeekDay![0];

      // to find any numbers in our selected day
      const match = day!.match('[\\d -]+');

      // If match was found and has a length greater than zero, take the first entry
      // Else use 1
      recurrence = (match && match!.length > 0) ? match![0] : '1';
    }
    // returns the found recurrence
    return recurrence;
  }

  /**
   * Opens edit dialog of the cleanTime. If edit is true, edit the selected CleanTime, if edit is false,
   */
  public async onUseCleanTime(cleanTime: CleanTime, edit = true, useOrigin = false): Promise<void> {
    try {
      // get CleanTime from api
      const apiCleanTime = await this.getCleanTimeAction(useOrigin ? cleanTime.origin : cleanTime.id!);
      apiCleanTime.recurrence = this.getRecurrenceFromByWeek(apiCleanTime);

      // if by weekday is null, every day was selected
      if (apiCleanTime.byWeekDay === null) {
        apiCleanTime.byWeekDay = WEEKDAYS.map((val: string) => val);
      }

      // opens the dialog box
      this.showJobDialog(edit, apiCleanTime, true);
    } catch (e: any) {
      if (e.status === 404) {
        // Shows an error message that the cleanTime is already deleted and cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.CLEANTIME_ALREADY_DELETED');
      } else {
        // Shows a generic error message that the cleanTime cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.COULD_NOT_EDIT_CLEANTIME');
      }
    }
  }

  public showJobDialog(editMode: boolean = false, apiCleanTime?: CleanTime, insertAttributes = false): void {
    this.autoInsert = false;

    // Reset Job Dialog and show it in the end.
    this.resetJobDialog = true;
    this.addJobDialog = true;

    this.$nextTick(() => {
      this.resetJobDialog = false;

      // if job dialog should be opened in edit mode
      // Wait a dom update to tell the job manage component to do so
      if (insertAttributes) {
        this.autoInsert = true;

        // waits a dom update and inserts the existing cleanTime data to the form
        this.$nextTick(() => {
          // Call the Edit method for the cleantime
          (this.$refs.jobManageComponent as JobManageComponent).setEditCleanTime(apiCleanTime!, editMode);
        });
      }
    });
  }

  // TODO after new design: implement more efficient reload
  public async reloadCalender(dates?: { startDate: Moment, endDate: Moment }) {
    if (dates) {
      this.filterData.dates = [];
      const startDate = dates.startDate.clone();
      for (let i = 0; i < 7; i++) {
        this.filterData.dates.push(startDate.toISOString());
        startDate.add(1, 'days');
      }
    }
    await this.loadJobsAction({filterData: this.filterData, discardCache: true});
  }

  private changeGroup(value: 'none' | 'customer' | 'employee') {
    this.groupBy = value;
  }

  public selectedStatus: string[] = [];

  private updateStatus(jobStatus: string[]) {
    this.selectedStatus = jobStatus;
  }
}
